import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './user'
import dashboard from './dashboard'
import project from './project'
import revisions from './revisions'
import tasks from './tasks'
import todos from './todos'
import notificationEmails from './notification-emails'
import settings from './settings'
import notes from './notes'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    validationErrors: null,
  },

  mutations: {
    setValidationErrors: (state, payload) => {
      state.validationErrors = payload.errors
    },
  },

  actions: {},

  modules: {
    user,
    dashboard,
    project,
    revisions,
    tasks,
    todos,
    notificationEmails,
    settings,
    notes,
  },

  plugins: [
    createPersistedState({
      paths: ['user'],
    }),
  ],
})
