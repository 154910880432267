export default {
  namespaced: true,

  state: () => ({
    emails: [],
  }),

  getters: {
    emails: state => state.emails,
  },

  mutations: {
    setEmails (state, payload) {
      state.emails = payload
    },
    addEmail (state, payload) {
      state.emails.push(payload)
    },
    deleteEmail (state, id) {
      const index = state.emails.findIndex(email => email.id === id)
      if (index !== -1) {
        state.emails.splice(index, 1)
      }
    },
  },

  actions: {
    async fetchEmails ({ commit }, user_id) {
      const res = await window.axios.get('notification-email', {
        params: {
          user_id,
        },
      })
      commit('setEmails', res.data)
    },
    async addEmail ({ commit }, payload) {
      const res = await window.axios.post('notification-email', payload)
      commit('addEmail', res.data)
    },
    async deleteEmail ({ commit }, id) {
      await window.axios.delete(`notification-email/${id}`)
      commit('deleteEmail', id)
    },
  },
}
