export default {
  namespaced: true,

  state: () => ({
    projects: [],
  }),

  mutations: {
    setProjects (state, payload) {
      state.projects = payload
    },
  },

  actions: {
    async fetchProjects ({ commit }, payload) {
      if (payload?.job_id) {
        payload.job_id = payload.job_id.substring(0, 13)
      }
      const res = await window.axios.get('project', {
        params: payload,
      })
      commit('setProjects', res.data)
    },
  },
}
