import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    todos: [],
  }),

  getters: {
    todos: state => state.todos,
  },

  mutations: {
    setTodos (state, payload) {
      state.todos = payload
    },
    addTodo (state, payload) {
      state.todos.push(payload)
    },
    updateTodo (state, payload) {
      const index = state.todos.findIndex(todo => todo.id === payload.id)
      if (index !== -1) {
        Vue.set(state.todos, index, payload)
      }
    },
    deleteTodo (state, id) {
      const index = state.todos.findIndex(todo => todo.id === id)
      if (index !== -1) {
        state.todos.splice(index, 1)
      }
    },
  },

  actions: {
    async fetchTodos ({ commit }, task_id) {
      const res = await window.axios.get('todo', {
        params: {
          task_id,
        },
      })
      commit('setTodos', res.data)
    },
    async addTodo ({ commit, dispatch }, task_id) {
      const res = await window.axios.post('todo', { task_id })
      commit('addTodo', res.data)
      dispatch('fetchTask', res.data, { root: true })
    },
    async updateTodo ({ commit, dispatch }, payload) {
      const res = await window.axios.put(`todo/${payload.id}`, payload)
      commit('updateTodo', res.data)
      dispatch('fetchTask', res.data, { root: true })
    },
    async updateTodoEmployee ({ commit, dispatch }, payload) {
      const res = await window.axios.put(`todo/${payload.id}/status`, payload)
      commit('updateTodo', res.data)
      dispatch('fetchTask', res.data, { root: true })
    },
    async deleteTodo ({ commit }, id) {
      await window.axios.delete(`todo/${id}`)
      commit('deleteTodo', id)
    },
  },
}
