import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    revisions: [],
    currentRevision: null,
  }),

  getters: {
    revisions: state => state.revisions,
    currentRevision: state => state.currentRevision,
  },

  mutations: {
    setRevisions (state, payload) {
      state.revisions = payload
    },
    addRevision (state, payload) {
      state.revisions.push(payload)
    },
    updateRevision (state, payload) {
      const index = state.revisions
        .findIndex(revision => revision.id === payload.id)
      if (index !== -1) {
        Vue.set(state.revisions, index, payload)
      }
    },
    setCurrentRevision (state, index) {
      state.currentRevision = index
    },
  },

  actions: {
    async fetchRevisions ({ commit }, project_id) {
      const res = await window.axios.get('revision', {
        params: {
          project_id,
        },
      })
      commit('setRevisions', res.data)
    },
    fetchRevision: {
      root: true,
      async handler ({ commit }, payload) {
        const res = await window.axios.get(`revision/${payload.revision_id}`)
        commit('updateRevision', res.data)
      },
    },
    async addRevision ({ state, commit }, project_id) {
      const payload = {
        name: `Revision ${state.revisions.length + 1}`,
        project_id,
      }
      const res = await window.axios.post('revision', payload)
      commit('addRevision', res.data)
      commit('setCurrentRevision', state.revisions.length - 1)
    },
    async updateRevision ({ commit }, payload) {
      const res = await window.axios.put(`revision/${payload.id}`, payload)
      commit('updateRevision', res.data)
    },
  },
}
