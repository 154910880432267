import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import store from '../store'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    component: () => import('../views/admin/Dashboard'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/register',
    component: () => import('../views/auth/Register'),
  },
  {
    path: '/login',
    component: () => import('../views/auth/Login'),
  },
  {
    path: '/project/:job_id',
    component: () => import('../views/Project'),
  },
  {
    path: '/project-form/:job_id?',
    component: () => import('../views/admin/ProjectForm'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notification-email',
    component: () => import('../views/admin/NotificationEmail'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notes',
    component: () => import('../views/admin/Notes'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    component: () => import('../views/admin/Settings'),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['user/user'].token) {
      next()
    } else {
      next({
        path: '/login',
      })
    }
    next()
  } else {
    next()
  }
})

export default router
