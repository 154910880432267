import { ACCEPTED } from '../shared'
import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    tasks: [],
    files: [],
    taskId: null,
  }),

  getters: {
    tasks: state => state.tasks,
    files: state => state.files,
    currentTask: state => state.tasks.findIndex(
      task => task.id === state.taskId,
    ),
    task: state => state.tasks.find(
      task => task.id === state.taskId,
    ),
    taskId: state => state.taskId,
  },

  mutations: {
    setTasks (state, payload) {
      state.tasks = payload
    },
    addTask (state, payload) {
      state.tasks.push(payload)
    },
    updateTask (state, payload) {
      const index = state.tasks.findIndex(task => task.id === payload.id)
      if (index !== -1) {
        Vue.set(state.tasks, index, payload)
      }
    },
    deleteTask (state, id) {
      const index = state.tasks.findIndex(task => task.id === id)
      if (index !== -1) {
        state.tasks.splice(index, 1)
      }
    },
    setFiles (state, payload) {
      state.files = payload
    },
    setCurrentTask (state, id) {
      state.taskId = id
    },
  },

  actions: {
    async fetchTasks ({ commit }, revision_id) {
      const res = await window.axios.get('task', {
        params: {
          revision_id,
        },
      })
      commit('setTasks', res.data)
    },
    fetchTask: {
      root: true,
      async handler ({ commit, dispatch }, payload) {
        const res = await window.axios.get(`task/${payload.task_id}`)
        commit('updateTask', res.data)
        dispatch('fetchRevision', res.data, { root: true })
      },
    },
    async addTask ({ state, getters, commit, dispatch }, revision_id) {
      const payload = {
        name: `Task ${state.tasks.length + 1}`,
        revision_id,
        floor: getters.task?.floor,
      }
      const res = await window.axios.post('task', payload)
      commit('addTask', res.data)
      commit('setCurrentTask', res.data.id)
      dispatch('fetchRevision', res.data, { root: true })
    },
    async updateTask ({ commit, dispatch }, payload) {
      const res = await window.axios.put(`task/${payload.id}`, payload)
      commit('updateTask', res.data)
      dispatch('fetchRevision', res.data, { root: true })
    },
    async updateTaskEmployee ({ commit, dispatch }, payload) {
      const res = await window.axios.put(`task/${payload.id}/status`, payload)
      commit('updateTask', res.data)
      dispatch('fetchRevision', res.data, { root: true })
    },
    async updateTaskStatus ({ state, commit, dispatch }, payload) {
      await dispatch('updateTask', payload)
      const nextTask = state.tasks.find(
        task => task.id > payload.id && task.status < ACCEPTED,
      )
      if (nextTask) {
        commit('setCurrentTask', nextTask.id)
      } else {
        commit('setCurrentTask', state.tasks.slice(-1)[0].id)
      }
    },
    async deleteTask ({ commit }, id) {
      await window.axios.delete(`task/${id}`)
      commit('deleteTask', id)
    },
    async fetchFiles ({ commit }, task_id) {
      const res = await window.axios.get('file', {
        params: {
          task_id,
        },
      })
      commit('setFiles', res.data)
    },
  },
}
