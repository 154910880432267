import router from '../router'

export default {
  namespaced: true,

  state: () => ({
    project: {
      job_id: '',
      user_id: null,
      floors_number: null,
      revisions: [],
    },
  }),

  getters: {
    project: state => state.project,
  },

  mutations: {
    setProject (state, payload) {
      state.project = payload
    },
  },

  actions: {
    async fetchProject ({ commit }, job_id) {
      const res = await window.axios.get(`project/${job_id}`)
      commit('setProject', res.data)
    },
    async createProject ({ commit }, payload) {
      const res = await window.axios.post('project', payload)
      commit('setProject', res.data)
      router.push(`/project/${res.data.job_id}`)
    },
    async updateProject ({ commit }, payload) {
      const res = await window.axios.put(`project/${payload.job_id}`, payload)
      commit('setProject', res.data)
    },
  },
}
