import { PUBLIC, PRIVATE } from '../shared'

export default {
  namespaced: true,

  state: {
    notes: [],
  },

  getters: {
    notes: state => state.notes,
    publicNotes: state => state.notes.filter(note => note.type === PUBLIC),
    privateNotes: state => state.notes.filter(note => note.type === PRIVATE),
  },

  mutations: {
    setNotes: (state, payload) => {
      state.notes = payload
    },
    addNote: (state, payload) => {
      state.notes.unshift(payload)
    },
    deleteNote: (state, payload) => {
      const index = state.notes.findIndex(note => note.id === payload.id)
      if (index !== -1) {
        state.notes.splice(index, 1)
      }
    },
  },

  actions: {
    async fetchNotes ({ commit }) {
      const { data } = await window.axios.get('notes')
      commit('setNotes', data)
    },
    async addNote ({ commit }, payload) {
      const { data } = await window.axios.post('notes', payload)
      commit('addNote', data)
    },
    async deleteNote ({ commit }, payload) {
      await window.axios.delete(`notes/${payload.id}`)
      commit('deleteNote', payload)
    },
  },
}
