export default {
  namespaced: true,

  state: () => ({
    settings: [],
    setting: {
      name: null,
      key: null,
      value: null,
    },
  }),

  getters: {
    settings: state => state.settings,
    registration_key: state => state.settings.find(
      setting => setting.key === 'registration_key',
    ) || state.setting,
  },

  mutations: {
    setSettings (state, payload) {
      state.settings = payload
    },
    changeSetting (state, payload) {
      console.log(payload)
      const index = state.settings.findIndex(s => s.id === payload.id)
      if (index !== -1) {
        state.settings[index] = payload
      }
    },
  },

  actions: {
    async fetchSettings ({ commit }) {
      const { data } = await window.axios.get('settings')
      commit('setSettings', data)
    },
    async updateSetting ({ commit }, payload) {
      const { data } = await window.axios.put(`settings/${payload.id}`, payload)
      commit('changeSetting', data)
    },
  },
}
