export const OPEN = 1
export const PENDING = 2
export const ACCEPTED = 3

export const PUBLIC = 0
export const PRIVATE = 1

export const STATUS_NAMES = [
  { text: 'Open', value: OPEN },
  { text: 'Pending', value: PENDING },
  { text: 'Accepted', value: ACCEPTED },
]

export const STATUS_ICONS = {
  [OPEN]: 'mdi-autorenew',
  [PENDING]: 'mdi-timer-sand',
  [ACCEPTED]: 'mdi-check',
}

export const STATUS_COLORS = {
  [OPEN]: 'deep-orange',
  [PENDING]: 'amber',
  [ACCEPTED]: 'teal',
}
