import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import {
  OPEN,
  ACCEPTED,
  PENDING,
  STATUS_COLORS,
  STATUS_ICONS,
  STATUS_NAMES,
  PUBLIC,
  PRIVATE,
} from './shared'

Vue.prototype.OPEN = OPEN
Vue.prototype.ACCEPTED = ACCEPTED
Vue.prototype.PENDING = PENDING
Vue.prototype.STATUS_COLORS = STATUS_COLORS
Vue.prototype.STATUS_ICONS = STATUS_ICONS
Vue.prototype.STATUS_NAMES = STATUS_NAMES
Vue.prototype.PUBLIC = PUBLIC
Vue.prototype.PRIVATE = PRIVATE

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
